.editor-wrapper {
  margin-bottom: 1.5rem;
  &.label {
    > .jodit_container {
      margin-top: 1rem;
      &.jodit_fullsize {
        margin: 0;
      }
    }
  }
}
