.c-image {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  &.contain {
    background-size: contain;
  }
  &.border {
    border: 1px solid rgba(0, 0, 0, .12);
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    margin: -25% 0 0 -25%;
    color: rgba(0, 0, 0, .42);
  }
}
