@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap&subset=latin-ext');
$border_color: #EAEAEA;
$color_primary: #174E59;
$color_table: #888D8F;
$background_primary: #174E59;
a {
  color: #174E59;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
body, html {
  height: calc(var(--vh, 1vh) * 100);
}
#root {
  background: #174E59;
  flex-direction: column;
  height: 100vh;
  .root-content {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 auto;
    z-index: 1;
    .root-page-content {
      background: #fff;
    }
  }
}
.MuiChip-root {
  background-color: #f4f9f3 !important;
}
//.data-grid
.data-grid {
  padding: 2.4rem 3rem 3rem;
  .data-grid-wrapper {
    border-color: $border_color;
    border-radius: 1.2rem;
    padding: 2rem;
  }
  .toolbar-head {
    padding: 0 0 2rem;
  }
  .toolbar-filters {
    background: #f4f9f3;
    border-radius: .8rem;
    margin: 0 0 1.5rem;
    padding: 2rem 2rem .5rem;
    svg {
      color: $color_table;
    }
  }
  .data-grid-scroll {
    table {
      thead tr th {
        color: #8A8F91;
        font-weight: 500;
        line-height: 2.4rem;
        &:first-child {
          padding-left: .5rem;
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: .5rem;
              &.is-edit-allow {
                padding-left: 1rem;
              }
            }
            &.swap {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .data-grid-pagination {
    color: $color_table;
    .MuiFormControl-root {
      max-width: 8rem;
    }
    .size .total {
      span {
        font-weight: 600;
      }
    }
    .pagination {
      .disabled {
        display: none;
      }
      li {
        padding: 0 .3rem;
        &.active a {
          background: #5FAF59;
          color: #fff;
        }
        a:hover,
        &.active a {
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}
.form-edit-wrapper {
  .form-edit-head {
    .form-edit-controls {
      white-space: nowrap;
    }
  }
}
.form-edit-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .MuiButton-root.btn-delete {
    background-color: transparent;
    box-shadow: none;
    font-size: 0;
    min-width: inherit;
    svg {
      font-size: 2rem;
      color: rgb(220, 0, 78);
    }
  }
}
.sync-notify {
  margin-top: -1.8rem;
  p {
    text-align: center;
    font-size: 1.6rem;
    padding: .5rem;
    margin: 0;
  }
}
//item-color
.item-color {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  border-radius: 50%;
  font-size: 0;
}
//reset-bexio-container
.reset-bexio-container {
  display: flex;
  align-items: center;
  .preview-item {
    flex: 1 auto;
  }
  button {
    margin: 0 0 0 2rem;
  }
}
//btn-debug-token
.MuiButton-root.btn-debug-token {
  position: relative;
  z-index: 1;
  .error-badge {
    position: absolute;
    top: -1px;
    right: -1px;
    padding: 0 5px;
    height: 16px;
    border-radius: 16px;
    background: #d20000;
    text-transform: none;
    font: bold 10px/16px "Roboto", "Helvetica", "Arial", sans-serif;
    color: #fff;
  }
}
//gray-image-uploader
.image-uploader-wrapper.gray-image-uploader .thumb{
  background-color: #174E59;
}
.TTViews .avatar.picture {
  background-color: #174E59;
  border-radius: 0!important;
  width: 56px!important;
}
