.user-nav {
  .user-button {
    padding-left: 0;
    text-align: left;
    line-height: 1.2;
    border-radius: 0;
    .MuiButton-label {
      display: flex;
      flex-wrap: nowrap;
      .MuiButton-startIcon {
        min-width: 50px;
        text-align: center;
        margin: 0;
        svg {
          display: block;
          margin: auto;
          font-size: 3.2rem;
          color: rgba(0, 0, 0, 0.42);
        }
        .avatar {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 50%;
          display: block;
          margin: auto;
        }
      }
    }
    .user-button-title {
      text-transform: none;
      width: 100%;
      strong {
        display: block;
      }
    }
  }
  &.hide-avatar {
    .MuiButton-label {
      padding-left: 1.3rem;
    }
  }
  &.hide-title {
    .MuiButton-root {
      min-width: inherit;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.collapsed {
  }
}
.user-nav_drop-down {
  .MuiPaper-root {
    border-radius: 1.2rem;
    margin: -.5rem 0 0;
  }
  .user-nav_drop-down-content {
    display: flex;
    flex-direction: column;
    .MuiButtonBase-root {
      border-radius: 0;
      border-bottom: 1px solid #EAEAEA;
      padding: .8rem 1.5rem;
      min-height: 4.9rem;
      min-width: 10rem;
      &:last-child {
        border-bottom: none;
      }
      .MuiButton-label {
        text-align: left;
        justify-content: left;
        font-size: 1.6rem;
        font-weight: 400;
        color: #202020;
        text-transform: none;
      }
    }
  }
}