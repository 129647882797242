.main-nav {
  flex: 1 auto;
  overflow: auto;
  .MuiTypography-body1 {
    font-size: 1.6rem;
  }
  svg {
    color: #D6D9D9;
  }
  .icon-wrapper {
    min-width: 5rem;
    text-align: center;
    svg {
      font-size: 2.4rem;
      display: block;
      margin: auto;
    }
  }
  .MuiListItem-gutters {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .title {
    text-transform: capitalize;
    .MuiListItemText-secondary {
      font-size: 1.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #303030;
      opacity: .5;
    }
  }
  .level-1 {
    transition: padding .2s ease-in;
    min-height: 4.8rem;
    padding-left: 0;
    .title {
      font-size: 1.4rem;
      transition: margin .2s ease-in;
    }
  }
  .level-2 .title {
    padding-left: 3.4rem
  }
  .level-3 .title {
    padding-left: 6.4rem
  }
  .active {
    background: #F8F9FA;
    > .icon-wrapper svg {
      color: #31586D;
    }
    > .title {
      .MuiListItemText-primary {
        font-weight: bold;
        color: #31586D;
      }
    }
  }
  &.collapsed {
    .level-1 {
      padding-top: 0;
      padding-bottom: 0;
      .title {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}
