.image-tooltip {
  white-space: nowrap;
  display: flex;
  align-items: center;
  .avatar, .no-image {
    margin: 0 1rem 0 0;
  }
  .no-image {
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.87);
    vertical-align: middle;
    display: flex;
    svg {
      display: block;
      margin: auto;
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}
.image-tooltip-content {
  img {
    display: block;
    padding: .5rem 0 0;
  }
  strong {
    font-size: 1.3rem;
    font-weight: normal;
    display: block;
    padding: 1rem;
  }
}
