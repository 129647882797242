.data-grid-wrapper-style {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  //head
  .head-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 .8rem;
    .left-side {
      h3 {
        font-size: 2.4rem;
        font-weight: 500;
        color: #174E59;
        text-transform: uppercase;
      }
    }
    .right-side {
      flex: 1 auto;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-end;
      .text-field-wrapper {
        margin: 0 0 0 1.5rem;
        width: 100%;
        max-width: 30rem;
        .MuiFormControl-root {
          margin: 0;
        }
      }
    }
  }
  .table-head {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    color: #8A8F91;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    div.col-head {
      padding-bottom: 1.2rem;
      padding-top: 1.2rem;
      > span {
        display: block;
        position: relative;
        padding: 0 2rem 0 0;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
        span {
          display: block;
          text-overflow: ellipsis;
          position: relative;
          overflow: hidden;
        }
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          right: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #888D8F;
          opacity: 0;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          right: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #888D8F;
          opacity: 0;
        }
        &.asc {
          &:before {
            opacity: .5;
          }
          &:after {
            opacity: 1;
          }
        }
        &.desc {
          &:before {
            opacity: 1;
          }
          &:after {
            opacity: .5;
          }
        }
      }
    }
  }
  .scroll-bar {
    border: 1px solid #EAEAEA;
    border-radius: 1.2rem;
    flex: 1 auto;
  }
  .table-row {
    border-bottom: 1px solid #EAEAEA;
    > div {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      position: relative;
    }
  }
  //pagination
  .grid-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pagination {
      display: flex;
      padding: 0;
      margin: 2rem 0 0;
      list-style: none;
      align-items: center;
      li {
        line-height: 3rem;
        svg {
          vertical-align: middle;
        }
        a {
          display: inline-block;
          min-width: 3.6rem;
          min-height: 3.6rem;
          line-height: 3.6rem;
          font-weight: 500;
          border-radius: 4px;
          text-align: center;
          padding: 0 .5rem;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          color: #174E59;
          &:hover {
            color: #31586d;
            text-decoration: none;
            background: #f8f9fa;
          }
          &:focus {
            text-decoration: none;
          }
          svg {
            line-height: 1;
            display: inline-block;
            vertical-align: top;
            margin: .6rem 0 0;
          }
          span.left {
            svg {
              margin: .6rem 1rem 0 0;
            }
          }
          span.right {
            svg {
              margin: .6rem 0 0 1rem;
            }
          }
        }
        a:hover,
        &.active {
          border-radius: 4px;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
        &.active {
          pointer-events: none;
          a {
            background: #5FAF59;
            color: #fff;
            font-weight: bold;
          }
        }
        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
        &.next a {
          padding: 0 .5rem 0 1rem;
          margin: 0 0 0 1rem;
        }
        &.previous a {
          padding: 0 1rem 0 .5rem;
          margin: 0 1rem 0 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .data-grid-wrapper-style {
    .head-grid {
      flex-direction: column;
      > div {
        min-width: 100%;
        h3 {
          text-align: center;
        }
        &.right-side {
          .text-field-wrapper {
            max-width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}