.preview-item {
  border-bottom: 1px solid #EAEAEA;
  margin: 0 0 1.5rem;
  position: relative;
  .value {
    padding: .4rem 0 .6rem;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label {
    font-size: 1.2rem;
    color: #888D8F;
    padding: 0;
  }
  &.checkbox {
    border-bottom: 0;
    display: flex;
    align-items: center;
    .label {
      padding: 0 .3rem;
      font-size: inherit;
      color: inherit;
    }
  }
  &.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .value {
      font-size: 2rem;
      font-weight: 500;
      color: inherit;
    }
  }
  .preview-secret-value {
    padding: .4rem 0 .6rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    button {
      margin: -.5rem 0;
    }
    .value {
      flex: 1 auto;
      padding: 0 1rem 0 0;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
