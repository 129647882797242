.data-grid {
  position: relative;
  padding: 2.4rem 3.2rem 3.2rem;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: hidden;
  .toolbar-head {
    display: flex;
    align-items: center;
    padding: 0 0 2.4rem;
    .title {
      flex: 0 1 auto;
      width: 100%;
    }
    .toolbar-controls {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 0 0 0 1rem;
      }
    }
  }
  .data-grid-wrapper {
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .12);
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2.8rem 2.5rem 2.2rem;
  }
  .data-grid-scroll {
    flex: 1 1 auto;
    overflow: auto;
    position: relative;
    min-height: 15rem;
    table {
      min-width: 28rem;
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
      thead {
        tr {
          th {
            border-bottom: 3px solid rgba(0, 0, 0, .12);
            font-weight: 700;
            font-size: 1.2rem;
            padding: .5rem 1rem;
            pointer-events: none;
            text-align: left;
            text-transform: capitalize;
            color: #65646c;
            position: relative;
            z-index: 4;
            background: #fff;
            will-change: transform;
            &:after {
              content: '';
              position: absolute;
              display: block;
              left: 0;
              right: 0;
              bottom: calc(-1rem - 1px);
              height: 1rem;
              //background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .15)), to(transparent));
              background: linear-gradient(180deg, rgba(0, 0, 0, .15) 0, transparent);
              opacity: 0;
              transition: opacity .35s ease-in-out;
              pointer-events: none;
            }
            svg {
              display: inline-block;
              vertical-align: middle;
            }
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            &.sortable {
              pointer-events: auto;
              cursor: pointer;
              &.active {
                border-bottom-color: #65646c;
              }
            }
            &.data-grid-col-controls {
              z-index: 8;
            }
            &.no-wrap {
              white-space: nowrap;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            background: #fff;
            padding: 1rem;
            vertical-align: middle;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
            position: relative;
            z-index: 2;
            &.is-edit-allow {
              cursor: pointer;
              min-width: 150px;
              &.is-edit, &:hover {
                position: relative;
                &:before {
                  position: absolute;
                  top: .3rem;
                  left: .3rem;
                  right: .3rem;
                  bottom: .3rem;
                  content: '';
                  border: 1px solid rgba(0, 0, 0, .12);
                  border-radius: 4px;
                }
              }
              &.is-edit {
                z-index: 3 !important;
              }
            }
            .grid-editor {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              > form > div:first-child {
                z-index: 2;
                background: #fff;
                box-shadow: 0 0.2rem 1rem -0.1rem rgba(0, 0, 0, .25);
                border: 1px solid rgba(0, 0, 0, .12);
                box-sizing: border-box;
                border-radius: 4px 4px 4px 0;
                padding: .2rem .45rem;
                position: absolute;
                height: 100%;
                width: 100%;
                .MuiFormControl-root {
                  width: 100%;
                }
              }
              .grid-editor-footer {
                position: absolute;
                z-index: 1;
                top: 100%;
                left: 0;
                background: #fff;
                border-radius: 0 0 4px 4px;
                box-shadow: 0 0.2rem 1rem -0.1rem rgba(0, 0, 0, .25);
                border: 1px solid rgba(0, 0, 0, .12);
                border-top: 0;
                button:first-child {
                  border-radius: 0 0 0 4px;
                }
                button:last-child {
                  border-radius: 0 0 4px 0;
                }
              }
            }
            &.data-grid-col-controls {
              padding: .2rem;
              white-space: nowrap;
              width: 5rem;
              position: relative;
            }
            //templates
            &.boolean {
              min-width: 0;
              width: 5rem;
              text-align: center;
              svg {
                display: block;
                margin: auto;
              }
            }
            &.date {
              white-space: nowrap;
            }
            &.data-grid-col-controls {
              z-index: 6;
            }
            //controls
            // -swap
            &.swap {
              padding: 0;
              position: relative;
              min-height: 5rem;
              > div {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                button {
                  width: 100%;
                  min-width: 2.5rem;
                  padding: 0;
                  margin: 0;
                  border-radius: 0;
                  flex: 1 auto;
                }
              }
            }
          }
          &:nth-child(2n) {
            td {
              background: #f8f9fa;
            }
          }
        }
      }
      thead tr th.data-grid-col-controls,
      tbody tr td.data-grid-col-controls {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1rem;
          width: 1rem;
          opacity: 0;
          transition: opacity .35s ease-in-out;
          pointer-events: none;
          background: linear-gradient(-90deg, rgba(0, 0, 0, .15) 0, transparent);
        }
        &.swap:before {
          left: auto;
          right: -1rem;
          background: linear-gradient(90deg, rgba(0, 0, 0, .15) 0, transparent);
        }
      }
      thead tr th.data-grid-col-controls {
        &:before {
          background: linear-gradient(90deg, #fff 0, #d8d8d8);
        }
        &.swap:before {
          background: linear-gradient(-90deg, #fff 0, #d8d8d8);
        }
      }
      &.is-scroll-y {
        thead tr th:after {
          opacity: 1;
        }
      }
      &.is-scroll--x {
        thead tr th.data-grid-col-controls,
        tbody tr td.data-grid-col-controls {
          &:before {
            opacity: 1;
          }
          &.swap:before {
            opacity: 0;
          }
        }
      }
      &.is-scroll-x {
        thead tr th.data-grid-col-controls.swap:before,
        tbody tr td.data-grid-col-controls.swap:before {
          opacity: 1;
        }
      }
    }
  }
  .data-grid-row-controls {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  .toolbar-filters {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0 0 .5rem;
    > * {
      margin: 0 1.5rem 1.5rem 0;
      .MuiFormControl-root {
        margin: 0;
      }
    }
  }
  .data-grid-pagination {
    padding: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .size {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      text-transform: capitalize;
      .MuiFormControl-root {
        margin-bottom: 0;
      }
      .title {
        padding: 0 1rem 0 0;
      }
      .total {
        padding: 0 0 0 2rem;
      }
    }
    .pagination {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      align-items: center;
      li {
        line-height: 3rem;
        svg {
          vertical-align: middle;
        }
        a {
          display: inline-block;
          min-width: 3rem;
          border-radius: 4px;
          text-align: center;
          padding: 0 .5rem;
          cursor: pointer;
          outline: none;
          box-sizing: border-box;
          &:hover {
            color: #31586d;
            text-decoration: none;
            background: #f8f9fa;
          }
          &:focus {
            text-decoration: none;
          }
        }
        &.active {
          pointer-events: none;
          a {
            background: #f8f9fa;
            color: #31586d;
            font-weight: bold;
          }
        }
        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }
    }
  }
}
.data-grid_filter-date-multiple_wrapper {
  position: relative;
  z-index: 10;
}
.data-grid-delete-dialog {
  .MuiTypography-h6 {
    font-weight: 400;
    strong {
      padding: 0 .5rem;
    }
  }
}
