.text-field-wrapper.select-mobile-input {
  .Mui-disabled .MuiInputAdornment-root {
    opacity: .5;
  }
  .btn-clear {
    min-width: inherit;
    min-height: inherit;
    padding: 0 .5rem;
    svg {
      font-size: 1.8rem;
      margin: 0 0 .2rem;
    }
  }
  svg {
    color: #888D8F;
  }
}
.select-mobile-dialog {
   display: flex;
   flex-direction: column;
   border-radius: 0;
   bottom: auto;
   min-height: 100%;
   .MuiDialogTitle-root {
     padding: .5rem;
     border-radius: 0;
     display: flex;
     align-items: center;
     border-bottom-width: 2px;
     .MuiTypography-h6 {
       font-size: 1.4rem;
       text-transform: none;
     }
     .btn-dialog-close {
       padding: 0 .5rem 0 1rem;
       flex: auto;
       margin: 0;
       svg {
         font-size: 2rem;
         line-height: 1;
       }
     }
   }
   .MuiDialogContent-root {
     padding: 0 !important;
     flex: 1 auto;
     overflow: hidden;
     display: flex;
     flex-direction: column;
     .search {
       border-bottom: 2px solid #EAEAEA;
       display: flex;
       min-height: 4.7rem;
       .MuiInputBase-root {
         flex: 1 auto;
         input {
           padding-left: 1rem;
           padding-right: 1rem;
         }
       }
       .MuiIconButton-root {
         padding: 0 1rem;
         svg {
           font-size: 1.7rem;
         }
       }
     }
     div.items {
       flex: 1 auto;
       overflow: auto;
       width: 100%;
       ul {
         margin: 0;
         padding: 0;
         li {
           padding: 1rem 2rem;
           border-bottom: 1px solid #f5f5f5;
           &.selected {
             background: #f5f5f5;
             font-weight: 500;
           }
         }
       }
     }
   }
 }