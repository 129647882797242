.login-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #f8f9fa;
  .login-page-content {
    display: flex;
    max-width: 117rem;
    width: 100%;
    height: 100%;
    position: relative;
    > div {
      min-width: 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      padding: 0 5rem;
      &.left-side {
        svg {
          max-width: 100%;
        }
      }
      &.right-side {
        padding: 0 7rem;
      }
    }
  }
  .footer {
    width: 100%;
    position: relative;
    .left-side {
      min-width: 50%;
      max-width: 50%;
      display: flex;
      justify-content: flex-end;
      padding: 3rem;
    }
  }
}
