$drawer_width_open: 25rem;
$drawer_width_close: 5rem;
#root {
  display: flex;
  height: 100vh;
  .root-drawer {
    position: relative;
    width: $drawer_width_close;
    transition: width .25s ease-in-out;
    & > div {
      position: absolute;
      width: $drawer_width_close;
      transition: width .25s ease-in-out, box-shadow .25s ease-in-out, background .25s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      > .user-nav, .main-nav {
        width: $drawer_width_open;
      }
    }
    &.opened {
      width: $drawer_width_open;
      & > div {
        background: #fff;
        width: $drawer_width_open;
      }
      .controls {
        text-align: right;
      }
      &.hover > div {
        box-shadow: none;
      }
    }
    &.hover > div {
      background: #fff;
      width: $drawer_width_open;
      box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.12);
    }
  }
  .root-page-content {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #f8f9fa;
  }
}
