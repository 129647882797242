.login-form {
  width: 100%;
  max-width: 40rem;
  .title {
    font-size: 2.4rem;
    font-weight: 700;
    padding: 0 0 1rem;
    text-transform: capitalize;
  }
  .state-email,
  .state-code {
    padding: 1rem 0 0;
  }
  .code-inputs {
    display: flex;
    margin: 0 -.5rem;
    .input-bootstrap {
      margin: 0 .5rem;
    }
    input {
      text-align: center;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .state-controls {
    padding: 2rem 0 0;
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0 0 0 1rem;
    }
  }
}
